import React from "react";

import { Slide } from "react-awesome-reveal";
import Analytic from "../../images/home/Analytic.svg";
import Digital from "../../images/home/Digital.svg";
import UXDesign from "../../images/home/UXDesign.svg";
import DataOps from "../../images/home/DataOps.svg";
import FullStackDev from "../../images/home/FullStackDev.svg";
import CloudPlatform from "../../images/home/CloudPlatform.svg";
import MobileWeb from "../../images/home/MobileWeb.svg";

const allServices = [
  {
    id: "one",
    icon: <Digital className="w-16 h-16 mx-auto md:m-0" />,
    text: "Digital Services",
    content: `Aligning Modern Tech to Deliver Innovative Outcomes`,
  },
  {
    id: "two",
    icon: <FullStackDev className="w-16 h-16 mx-auto" />,
    text: "Full Stack Development",
    content: `Delivering Comprehensive Software Development`,
  },
  {
    id: "three",
    icon: <UXDesign className="w-16 h-16 mx-auto" />,
    text: "User Experience & Design",
    content: `Collaborating with End Users to Build Real Solutions`,
  },
  {
    id: "four",
    icon: <DataOps className="w-16 h-16 mx-auto" />,
    text: "DataOps",
    content: `Ensuring Data Gets Where It Needs To Go`,
  },
  {
    id: "five",
    icon: <MobileWeb className="w-16 h-16 mx-auto" />,
    text: "Mobile & Web",
    content: `Designing Apps and Tools to Maximize Mission Impact`,
  },
  {
    id: "six",
    icon: <Analytic className="w-16 h-16 mx-auto" />,
    text: "Data Science & Analytics",
    content: `Turning Data Into Intelligence`,
  },
  {
    id: "seven",
    icon: <CloudPlatform className="w-16 h-16 mx-auto" />,
    text: "Cloud & Platform",
    content: `Optimizing Infrastructure for Operational Environments`,
  },
];

// const generateDirection = (index) => {
//   return index % 2 === 0 ? "left" : "right";
// };

export default function ServicesSection() {
  return (
    <section className="h-full p-4 mt-8 md:p-6 lg:my-16 xl:py-24 2xl:py-48">
      <div className="container mx-auto">
        <div className="text-copy-primary text-center">
          <Slide
            triggerOnce
            direction="right"
            childClassName="text-copy-primary"
          >
            <h2 className="text-left text-3xl lg:text-4xl font-semibold lg:text-left xl:text-5xl">
              Rise above the Status Quo
            </h2>
            <p className="text-left leading-relaxed mt-6 text-lg md:text-xl">
              Our mission-focused software solutions give our customers an edge
              over the opposition.
            </p>
          </Slide>
        </div>
        <div className="grid grid-col-1 md:grid-cols-2 md:gap-1">
          {allServices.map(({ icon, text, content }) => (
            <div
              className="text-copy-primary mt-16 flex md:flex-row items-center"
              key={text}
            >
              <Slide triggerOnce direction="left">
                {icon}
              </Slide>
              <Slide triggerOnce direction="right">
                <div className="flex flex-col ml-4">
                  <h3 className="mt-0 md:mt-3 text-2xl font-semibold">
                    {text}
                  </h3>
                  <p className="text-left mt-0 text-base lg:text-lg">
                    {content}
                  </p>
                </div>
              </Slide>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
